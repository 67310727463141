import {
  FundAccount,
  User,
} from "providers/rest-data-provider/utils/interfaces";
export enum TRANSFER_TRANSACTION_TYPE_ENUM {
  CASH = "CASH",
  INVESTMENTS = "INVESTMENTS",
  GRANTVESTMENT = "GRANTVESTMENT",
}
export enum DIVIDEND_DISTRIBUTION_TYPE {
  CASH = "CASH",
  UNIT = "UNIT",
}
export enum TRANSACTION_STATUS {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  CANCELED = "CANCELED",
  PROCESSED = "PROCESSED",
}
export interface ITransferTransaction {
  transferId: number;
  senderFund: FundAccount;
  receiverFund: FundAccount;
  user: User;
  amount: number;
  reference: string;
  transferType: TRANSFER_TRANSACTION_TYPE_ENUM;
  assetType: DIVIDEND_DISTRIBUTION_TYPE;
  status: TRANSACTION_STATUS;
  createdAt?: Date;
  updatedAt?: Date;
  rejectionReason?: string | undefined;
  executionDate?: Date;
  receiverTransactionId?: number;
  senderTransactionId?: number;
}
