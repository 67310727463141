import { CircularProgress, Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useUpdate } from "@refinedev/core";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import {
  CashInformation,
  IContributionTransaction,
  SecuritiesInformation,
} from "interfaces/contribution-transaction";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { moneyFormat } from "utils/money-format";

interface ModalProps {
  visible: boolean;
  data: {
    contributionTransaction: IContributionTransaction | undefined;
    value: "PENDING" | "ACCEPTED" | "REJECTED";
  };
  close: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EvaluateContributionTransactionModal: React.FC<ModalProps> = ({
  visible,
  close,
  data,
}) => {
  const [appliedAmount, setAppliedAmount] = useState(0);
  const [requestedAmount, setRequestedAmount] = useState(0);
  const [reference, setReference] = useState("");
  const [extraNotes, setExtraNotes] = useState("");
  const [marketRate, setMarketRate] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [rejectionReason, setRejectionReason] = useState("");
  const date = new Date().toISOString().substring(0, 10);
  const [executionDate, setExecutionDate] = useState({
    value: date,
    error: false,
  });
  const [error, setError] = useState(false);

  const { mutate, isLoading } = useUpdate();
  const navigate = useNavigate();
  useEffect(() => {
    if (data.contributionTransaction?.type === "CASH") {
      let cashInfo = data.contributionTransaction
        .contributionInformation as CashInformation;
      setAppliedAmount(cashInfo.requestedAmount);
      setRequestedAmount(cashInfo.requestedAmount);
    }
    if (data.contributionTransaction?.type === "SECURITIES") {
      let securitiesInformation =
        data.contributionTransaction.contributionInformation as SecuritiesInformation;
      setQuantity(securitiesInformation.quantity)
    }
  }, [
    data.contributionTransaction?.contributionInformation,
    data.contributionTransaction?.type,
  ]);
  return (
    <Dialog open={visible} onClose={() => close(false)}>
      <DialogTitle color="primary">
        {data.value === "ACCEPTED" ? "Accept" : "Reject"} user contribution
        transaction
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Are you sure you want to{" "}
          {data.value === "ACCEPTED" ? "accept" : "reject"} this user
          contribution request?
        </Typography>
        {data.value === "ACCEPTED" ? (
          <div>
            {data.contributionTransaction?.type === "CASH" && (
              <TextField
                value={moneyFormat(requestedAmount)}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
                label="Requested Amount"
                name="requestedAmount"
                disabled
              />
            )}
            {data.contributionTransaction?.type === "SECURITIES" && (
              <>
                <TextField
                  value={quantity}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  label="Quantity"
                  name="quantity"
                  onChange={(e) => setQuantity(e.target.value as any)}
                /></>
            )}
            <TextField
              value={moneyFormat(appliedAmount)}
              error={error}
              helperText={error && "The applied amount is required"}
              margin="normal"
              fullWidth
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
              label="Estimated Applied Amount"
              name="appliedAmount"
              onChange={(e) => {
                setAppliedAmount(e.target.value as any);
                setError(false);
              }}
            />
            {data.contributionTransaction?.type === "SECURITIES" && (
              <>
                <TextField
                  value={marketRate}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  label="Estimated Market Rate"
                  name="marketRate"
                  onChange={(e) => setMarketRate(e.target.value as any)}
                /></>
            )}
            <TextField
              value={reference}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Reference (internal only)"
              name="Reference"
              onChange={(e) => setReference(e.target.value)}
            />
            <TextField
              value={extraNotes}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              multiline={true}
              rows={3}
              label="Note (added to contribution receipt letter to donor)"
              name="note"
              onChange={(e) => setExtraNotes(e.target.value)}
            />
            <TextField
              id="executionDate"
              name="executionDate"
              error={executionDate.error}
              helperText={
                executionDate.error &&
                "The date is required, and can not be negative value!"
              }
              defaultValue={executionDate.value}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="date"
              label="Execution Date"
              onChange={(event) => {
                setExecutionDate({
                  value: event.target.value,
                  error: false,
                });
              }}
            />
          </div>
        ) : (
          <>
            <TextField
              value={rejectionReason}
              error={error}
              helperText={error && "The rejection reason is required"}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Rejection reason"
              name="rejectionReason"
              multiline={true}
              rows={3}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button
            variant="contained"
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => {
              if (!appliedAmount && data.value === "ACCEPTED") {
                setError(true);
              } else if (!rejectionReason && data.value === "REJECTED") {
                setError(true);
              } else {
                mutate(
                  {
                    resource: "contribution-transactions",
                    values:
                      data.value === "ACCEPTED"
                        ? {
                          status: data.value,
                          executionDate: executionDate.value,
                          extraNotes: extraNotes,
                          fundId:
                            data.contributionTransaction?.fund.fundAccountId,
                          reference,
                          appliedAmount: parseFloat(
                            appliedAmount.toString().replace(",", "")
                          ),
                          marketRate: parseFloat(
                            marketRate.toString().replace(",", "")
                          ),
                          quantity: parseFloat(
                            quantity.toString().replace(",", "")
                          ),
                          type: data.contributionTransaction?.type,
                        }
                        : {
                          rejectionReason: rejectionReason,
                          status: data.value,
                          type: data.contributionTransaction?.type,
                        },
                    id: data.contributionTransaction?.contributionId as any,
                  },
                  {
                    onSuccess: () => {
                      if (
                        data.value === "ACCEPTED" &&
                        data.contributionTransaction?.type === "CASH"
                      ) {
                        navigate("/contribution-transactions/status/processed");
                      }
                      if (
                        data.value === "ACCEPTED" &&
                        data.contributionTransaction?.type === "SECURITIES"
                      ) {
                        navigate("/contribution-transactions");
                      }
                      if (data.value === "REJECTED") {
                        navigate("/contribution-transactions/status/rejected");
                      }
                      setError(false);
                      close(false);
                    },
                  }
                );
              }
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              close(false);
              setError(false);
              setAppliedAmount(0);
              setMarketRate(0);
              setReference("");
              setExtraNotes("");
            }}
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
