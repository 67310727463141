import { CircularProgress, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useUpdate } from "@refinedev/core";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import {
  IContributionTransaction,
  OtherAssetsInformation,
  SecuritiesInformation,
} from "interfaces/contribution-transaction";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { moneyFormat } from "utils/money-format";

interface ModalProps {
  visible: boolean;
  contributionTransaction: IContributionTransaction | undefined;
  close: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ProcessContributionTransactionModal: React.FC<ModalProps> = ({
  visible,
  close,
  contributionTransaction,
}) => {
  const [estimatedAmount, setEstimatedAmount] = useState(0);
  const [appliedAmount, setAppliedAmount] = useState(0);
  const [estimatedMarketRate, setEstimatedMarketRate] = useState(0);
  const [marketRate, setMarketRate] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [error, setError] = useState(false);
  const date = new Date().toISOString().substring(0, 10);
  const [executionDate, setExecutionDate] = useState({
    value: date,
    error: false,
  });
  const { mutate, isLoading } = useUpdate();
  const navigate = useNavigate();
  useEffect(() => {
    if (contributionTransaction?.type === "SECURITIES") {
      let data =
        contributionTransaction.contributionInformation as SecuritiesInformation;

      setEstimatedAmount(data.estimatedAmount || 0);
      setQuantity(data.quantity)
      setAppliedAmount(data.estimatedAmount || 0);
      setEstimatedMarketRate(data.estimatedMarketRate || 0);
      setMarketRate(data.estimatedMarketRate || 0);
    }
    if (contributionTransaction?.type === "OTHER") {
      let data =
        contributionTransaction.contributionInformation as OtherAssetsInformation;

      setEstimatedAmount(data.estimatedAmount || 0);
      setAppliedAmount(data.estimatedAmount || 0);
    }
  }, [
    contributionTransaction?.contributionInformation,
    contributionTransaction?.type,
  ]);
  return (
    <Dialog open={visible} onClose={() => close(false)}>
      <DialogTitle color="primary">
        Process user contribution transaction
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div>
          {contributionTransaction?.type === "SECURITIES" && (
            <>
              <TextField
                value={quantity}
                margin="normal"
                fullWidth
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
                label="Quantity"
                name="quantity"
                disabled
                onChange={(e) => setQuantity(e.target.value as any)}
              />
              <TextField
                value={moneyFormat(estimatedMarketRate)}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
                label="Estimated Market Rate"
                name="estimatedMarketRate"
                disabled
              />
              <TextField
                value={moneyFormat(marketRate)}
                error={error}
                helperText={error && "The market rate is required"}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
                label="Market Rate"
                name="marketRate"
                onChange={(e) => {
                  setMarketRate(e.target.value as any);
                  setError(false);
                }}
              />
            </>
          )}
          <TextField
            value={moneyFormat(estimatedAmount)}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            label="Estimated Amount"
            name="estimatedAmount"
            disabled
          />
          <TextField
            value={moneyFormat(appliedAmount)}
            error={error}
            helperText={error && "The applied amount is required"}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            type="text"
            label="Applied Amount"
            name="appliedAmount"
            onChange={(e) => {
              setAppliedAmount(e.target.value as any);
              setError(false);
            }}
          />
          <TextField
            id="executionDate"
            name="executionDate"
            error={executionDate.error}
            helperText={
              executionDate.error &&
              "The date is required, and can not be negative value!"
            }
            defaultValue={executionDate.value}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="date"
            label="Execution Date"
            onChange={(event) => {
              setExecutionDate({
                value: event.target.value,
                error: false,
              });
            }}
          />
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            variant="contained"
            onClick={() => {
              if (!appliedAmount) {
                setError(true);
              } else {
                mutate(
                  {
                    resource: "contribution-transactions",
                    values: {
                      appliedAmount: parseFloat(
                        appliedAmount.toString().replace(",", "")
                      ),
                      quantity: parseFloat(
                        quantity.toString().replace(",", "")
                      ),
                      marketRate: parseFloat(
                        marketRate.toString().replace(",", "")
                      ),
                      executionDate: executionDate.value,
                    },
                    id: `${contributionTransaction?.contributionId as any
                      }/process`,
                  },
                  {
                    onSuccess: () => {
                      navigate("/contribution-transactions/status/processed");
                      setError(false);
                      close(false);
                    },
                  }
                );
              }
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              close(false);
              setError(false);
            }}
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
