import React, { useState } from "react";
import { Box, Card, CardContent, Tab, Tabs, Typography } from "@mui/material";

import CustomTabPanel from "pages/grant-vestments/show";
import { RequestsList } from "components/list/Requests-list";
import { FUND_MOVEMENT_TYPE_ENUM } from "interfaces/fund-movements-history";
import { UsersList } from "components/list/user-list";

export const Home: React.FC = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };
  return (
    <>
      <Card sx={{ boxShadow: "none" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: `neutral.main`, p: 1 }}
              variant="subtitle1"
            >
              Requests
            </Typography>
          </Box>
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            className="record"
          >
            <Tab label="Pending Transactions" className="record" />
            <Tab label="To Be Processed Transactions " className="record" />
            <Tab label="Pending Users " className="record" />
          </Tabs>
          <CustomTabPanel value={currentTabIndex} index={0}>
            <RequestsList
              status={["PENDING"]}
              movementsTypes={[
                FUND_MOVEMENT_TYPE_ENUM.INVESTMENT,
                FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION,
                FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT,
                FUND_MOVEMENT_TYPE_ENUM.GRANT,
                FUND_MOVEMENT_TYPE_ENUM.TRANSFER,
              ]}
            />
          </CustomTabPanel>
          <CustomTabPanel value={currentTabIndex} index={1}>
            <RequestsList
              status={["APPROVED", "ACCEPTED"]}
              movementsTypes={[
                FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION,
                FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT,
                FUND_MOVEMENT_TYPE_ENUM.GRANT,
              ]}
            />
          </CustomTabPanel>
          <CustomTabPanel value={currentTabIndex} index={2}>
            <UsersList />
          </CustomTabPanel>
        </CardContent>
      </Card>
    </>
  );
};
