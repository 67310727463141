import { CircularProgress, Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useUpdate } from "@refinedev/core";
import { ITransferTransaction } from "interfaces/transfer-transaction";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  visible: boolean;
  data: {
    transferTransaction: ITransferTransaction | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED"
  };
  close: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EvaluateTransferTransactionModal: React.FC<ModalProps> = ({
  visible,
  close,
  data,
}) => {
  const [error, setError] = useState(false);
  const date = new Date().toISOString().substring(0, 10);

  const [executionDate, setExecutionDate] = useState({
    value: date,
    error: false,
  });

  const [rejectionReason, setRejectionReason] = useState("");
  const { mutate, isLoading } = useUpdate();
  const navigate = useNavigate();

  return (
    <Dialog open={visible} onClose={() => close(false)}>
      <DialogTitle color="primary">
        {data.value === "APPROVED"
          ? "Approve"
          : "Reject"}{" "}
        Transfer Transaction
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Are you sure you want to{" "}
          {data.value === "APPROVED"
            ? "Approve"
            : "reject"}{" "}
          this transfer transaction?
        </Typography>
        {data.value === "APPROVED" &&
          <TextField
            id="executionDate"
            name="executionDate"
            error={executionDate.error}
            helperText={
              executionDate.error &&
              "The date is required, and can not be negative value!"
            }
            defaultValue={executionDate.value}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="date"
            label="Execution Date"
            onChange={(event) => {
              setExecutionDate({
                value: event.target.value,
                error: false,
              });
            }}
          />}
        {data.value === "REJECTED" && (
          <>
            <TextField
              value={rejectionReason}
              error={error}
              helperText={error && "The rejection reason is required"}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Rejection reason"
              name="rejectionReason"
              multiline={true}
              rows={3}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button
            variant="contained"
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => {

              mutate(
                {
                  resource: "transfer-transactions",
                  values:
                    data.value === "APPROVED"
                      ? {
                        status: "PROCESSED",
                        executionDate: executionDate.value

                      }
                      : data.value === "REJECTED"
                        ? {
                          rejectionReason: rejectionReason,
                          status: data.value,
                          executionDate: date
                        }
                        : {
                          status: data.value,

                        },
                  id: `${data.transferTransaction?.transferId as any
                    }`,
                },
                {
                  onSuccess: () => {
                    navigate("/transfer-transactions");
                    setError(false);
                    close(false);
                    data.value === "APPROVED"
                      ? navigate("/transfer-transactions/status/processed")
                      : navigate("/transfer-transactions/status/rejected");
                  },
                }
              );
            }
            }
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              close(false);
              setError(false);
            }}
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
