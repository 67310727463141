import React, { useState } from "react";
import { useDataGrid, List, DateField } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { moneyFormat } from "utils/money-format";
import { ITransferTransaction, TRANSFER_TRANSACTION_TYPE_ENUM } from "interfaces/transfer-transaction";
import { EvaluateTransferTransactionModal } from "components/modal/evaluate-transfer-transaction";
export const PendingTransferTransactionList: React.FC<
  IResourceComponentsProps
> = () => {
  const { dataGridProps } = useDataGrid<ITransferTransaction>({
    queryOptions: { enabled: false },
  });
  const { data, isLoading } = useCustom({
    url: `transfer-transactions`,
    method: "get",
    config: {
      filters: [
        {
          field: "status",
          operator: "eq",
          value: "PENDING",
        },
      ],
    },
  });
  const [open, setOpen] = useState(false);
  const [decision, setDecision] = useState<{
    transferTransaction: ITransferTransaction | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED";
  }>({ transferTransaction: undefined, value: "PENDING" });
  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "transferId",
        flex: 1,
        headerName: "ID",
        maxWidth: 50,
        renderCell: function render({ value }: any) {
          return value;
        },
      },
      {
        field: "senderFund",
        flex: 1,
        headerName: "From",

        renderCell: function render({ value }: any) {
          return value.fundAccountName;
        },
      },
      {
        field: "receiverFund",
        flex: 1,
        headerName: "To",

        renderCell: function render({ value }: any) {
          return value.fundAccountName;
        },
      },
      {
        field: "user",
        flex: 1,
        headerName: "Advisor",

        renderCell: function render({ value }: any) {
          return `${value.firstName} ${value.lastName}`;
        },
      },
      {
        field: "transferType",
        flex: 1,
        headerName: "Type",
      },
      {
        field: "reference",
        flex: 1,
        headerName: "Reference",
      },

      {
        field: "amount",
        flex: 1,
        headerName: "Value",

        renderCell: function render({ row }) {
          if (row.transferType === TRANSFER_TRANSACTION_TYPE_ENUM.INVESTMENTS) {
            return <>{row.amount} unit(s)</>;
          }
          else {
            return <>${moneyFormat(row.amount)}</>;
          }
        },
      },
      {
        field: "status",
        flex: 1,
        headerName: "Status",
        width: 80
      },
      {
        field: "executionDate",
        flex: 1,
        headerName: "Date",
        renderCell: function render({ value }: any) {
          return <DateField value={value} />;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              {" "}
              <IconButton
                onClick={() => {
                  setDecision({
                    transferTransaction: row,
                    value: "APPROVED",
                  });
                  setOpen(true);
                }}
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setDecision({
                    transferTransaction: row,
                    value: "REJECTED",
                  });
                  setOpen(true);
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    []
  );

  return (
    <>
      <List title=" Pending Transfer Transaction List">
        {!isLoading && (
          <DataGrid
            {...dataGridProps}
            rows={data?.data?.data}
            getRowId={(row) => row.transferId}
            columns={columns}
            autoHeight
            loading={isLoading}
          />
        )}
      </List>
      {<EvaluateTransferTransactionModal
        visible={open}
        close={setOpen}
        data={decision}
      />}
    </>
  );
};
