import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  listItemClasses,
} from "@mui/material";
import { warm, neutral } from "style/color";
import { moneyFormat } from "utils/money-format";

import { ReactComponent as EyeIcon } from "./../../assets/icons/eye.svg";
import { ReactComponent as EyeClosedIcon } from "./../../assets/icons/eye-closed.svg";

import { useCustom } from "@refinedev/core";

import { FUND_LOG_TRANSACTION_TYPE_ENUM, FundBalanceLog } from "interfaces/fund-balance-log";
import { shortFormat } from "utils/date-format";


export const LogCard: React.FC = () => {
  const typesFilter = [
    { title: "Contributions", status: FUND_LOG_TRANSACTION_TYPE_ENUM.CONTRIBUTION },
    { title: "Grants", status: FUND_LOG_TRANSACTION_TYPE_ENUM.GRANT },
    { title: "Investments(buy)", status: FUND_LOG_TRANSACTION_TYPE_ENUM.INVESTMENT_BUY },
    { title: "Investments(sell)", status: FUND_LOG_TRANSACTION_TYPE_ENUM.INVESTMENT_SELL },
    { title: "Transfer(send)", status: FUND_LOG_TRANSACTION_TYPE_ENUM.TRANSFER_SEND },
    { title: "Transfer(receive)", status: FUND_LOG_TRANSACTION_TYPE_ENUM.TRANSFER_RECEIVE },
    { title: "GrantVestments", status: FUND_LOG_TRANSACTION_TYPE_ENUM.GRANTVESTMENT },
    { title: "Distribution", status: FUND_LOG_TRANSACTION_TYPE_ENUM.DISTRIBUTION },
    { title: "Fee", status: FUND_LOG_TRANSACTION_TYPE_ENUM.FEE },
    { title: "Adjustment", status: FUND_LOG_TRANSACTION_TYPE_ENUM.ADJUSTMENT },
  ];
  const [types, setTypes] = React.useState<string[]>([
    FUND_LOG_TRANSACTION_TYPE_ENUM.CONTRIBUTION,
    FUND_LOG_TRANSACTION_TYPE_ENUM.GRANT,
    FUND_LOG_TRANSACTION_TYPE_ENUM.INVESTMENT_BUY,
    FUND_LOG_TRANSACTION_TYPE_ENUM.INVESTMENT_SELL,
    FUND_LOG_TRANSACTION_TYPE_ENUM.GRANTVESTMENT,
    FUND_LOG_TRANSACTION_TYPE_ENUM.DISTRIBUTION,
    FUND_LOG_TRANSACTION_TYPE_ENUM.FEE,
    FUND_LOG_TRANSACTION_TYPE_ENUM.ADJUSTMENT,
    FUND_LOG_TRANSACTION_TYPE_ENUM.TRANSFER_RECEIVE,
    FUND_LOG_TRANSACTION_TYPE_ENUM.TRANSFER_SEND
  ]);


  const { data: paginatedData, isLoading } = useCustom({
    url: `fund/log`,
    method: "get",
    config: {
      query: {
        offset: 0,
        ...{ "filter[types]": [...types] },
      },
    },
  });


  const contains = (type: FUND_LOG_TRANSACTION_TYPE_ENUM) => {
    return types.includes(type);
  };
  const handleTypeChange = (type: FUND_LOG_TRANSACTION_TYPE_ENUM) => {
    setTypes((prev) => {
      const index = prev.findIndex((item) => item === type);
      let filterTypes = [];

      if (index !== -1) {
        filterTypes = [...prev];
        filterTypes.splice(index, 1);
      } else {
        filterTypes = [...prev, type];
      }
      return filterTypes;
    });
  };

  const logs = paginatedData?.data.data;

  return isLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <Card sx={{ boxShadow: "none" }}>
      <CardContent>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >

        </Box>
        <Grid sx={{ my: 1 }} container spacing={2}>
          {typesFilter.map((item, index) => {
            return (
              <Grid key={index} item>
                {contains(item.status) ? (
                  <Button
                    key={index}
                    onClick={() => handleTypeChange(item.status)}
                    startIcon={<EyeIcon />}
                    variant={"contained"}
                    color="primary"
                    size="large"
                  >
                    {item.title}
                  </Button>
                ) : (
                  <Button
                    key={index}
                    onClick={() => handleTypeChange(item.status)}
                    startIcon={<EyeClosedIcon />}
                    variant={"outlined"}
                    color="primary"
                    size="large"
                  >
                    {item.title}
                  </Button>
                )}
              </Grid>
            );
          })}
        </Grid>
        {logs.length !== 0 ? (
          <List
            className="successor"
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              mt: 2,
              [` & .${listItemClasses.root}:hover`]: {
                backgroundColor: warm[200],
                cursor: "pointer",
              },
            }}
          >
            {logs.map((log: FundBalanceLog, index: number) => {
              const { createdAt, fundAccount, transactionType, action, transactionId, previousAvailableBalance, previousTotalValue, newAvailableBalance, newTotalValue, amount, description } = log;
              return (
                <>
                  <ListItem key={index}>
                    <Grid alignItems="center" container>
                      <Grid item xs={12} md={1} textAlign={"start"}>
                        <Typography
                          sx={{
                            color: neutral[900]
                          }}
                          variant="bodyCopyRegular"
                          gutterBottom
                        >
                          <>

                            {shortFormat(createdAt)}
                          </>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={"center"}>
                        <ListItemText
                          sx={{
                            display: "inline-grid",
                            width: "auto",
                            flex: "none",
                          }}
                          primary={
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              gutterBottom
                            >
                              {fundAccount.fundAccountName}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              sx={{ color: neutral[600] }}
                              variant="smallCopyBold"
                            >{`By ${log.user}`}</Typography>
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={2} textAlign={"center"}>
                        <ListItemText
                          sx={{
                            display: "inline-grid",
                            width: "auto",
                            flex: "none",
                          }}
                          primary={
                            <Typography
                              sx={{ color: `neutral.main`, textTransform: "capitalize" }}
                              variant="bodyCopyBold"
                              gutterBottom

                            >
                              {transactionType.toLowerCase()} ({transactionId})
                            </Typography>
                          }
                          secondary={
                            <Typography
                              sx={{ color: neutral[600], textTransform: "capitalize", }}
                              variant="smallCopyBold"
                            >{action.toLocaleLowerCase()}</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={"center"}>
                        <ListItemText
                          sx={{
                            display: "inline-grid",
                            width: "auto",
                            flex: "none",
                          }}
                          secondary={
                            <Typography
                              sx={{ color: neutral[600] }}
                              variant="smallCopyBold"
                            >{description}</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={1} textAlign={"center"}>
                        <Typography
                          sx={{
                            px: 1,
                            py: 1,
                            color: neutral[900]
                          }}
                          variant="bodyCopyBold"
                          gutterBottom
                        >
                          <>

                            ${moneyFormat(amount)}
                          </>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={"center"}>
                        <ListItemText
                          sx={{
                            display: "inline-grid",
                            width: "auto",
                            flex: "none",
                          }}
                          primary={
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              gutterBottom
                            >
                              Available balance <br></br>${moneyFormat(newAvailableBalance)}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              sx={{ color: neutral[600] }}
                              variant="smallCopyBold"
                            >previous value: ${moneyFormat(previousAvailableBalance)}</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={"center"}>
                        <ListItemText
                          sx={{
                            display: "inline-grid",
                            width: "auto",
                            flex: "none",
                          }}
                          primary={
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              gutterBottom
                            >
                              Total value <br></br>${moneyFormat(newTotalValue)}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              sx={{ color: neutral[600] }}
                              variant="smallCopyBold"
                            >previous value: ${moneyFormat(previousTotalValue)}</Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  {index !== logs.length - 1 && (
                    <Divider key={index + 100} />
                  )}
                </>
              );
            })}
          </List>
        ) : (
          <Typography
            sx={{ mt: 4, color: neutral[600] }}
            variant="bodyCopyRegular"
          >
            You don't have any logs.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
