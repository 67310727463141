import {
  InputAdornment,
  TextField,
  TableRow,
  TableCell,
} from "@mui/material";

import { IInvestment } from "interfaces/investment";
import { getLast7Dates, isSameDay } from "utils/date-format";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import { useEffect, useState } from "react";
interface UpdateInvestmentCardProps {
  investment: IInvestment;
  updateInvestment: (
    ticker: string,
    id: number,
    marketPrice: number,
    dailyChange: number,
    date: Date
  ) => void;
}
export const UpdateInvestmentCard: React.FC<UpdateInvestmentCardProps> = ({
  investment,
  updateInvestment,
}) => {
  const last7Dates = getLast7Dates();

  const { ticker, investmentMarketPriceHistory } = investment;
  const [history, setHistory] = useState<any[]>([])


  useEffect(() => {
    const test: any[] = [];
    last7Dates.forEach((date: string, index: number) => {
      const investmentHistory = investmentMarketPriceHistory?.find((hist) => isSameDay(new Date(date), new Date(hist.referenceDate)))
      if (investmentHistory) {
        test.push({
          date: date, marketPrice: investmentHistory.marketPrice, marketPriceId: investmentHistory.marketPriceId, locked: investmentHistory.locked
        })
      }
      else {
        test.push({
          date: date, marketPrice: 0, marketPriceId: -1, locked: false
        })
      }
      setHistory(test)


    })

  }, [investmentMarketPriceHistory, last7Dates])
  const updateValue = (value: number, index: number) => {

    if (investmentMarketPriceHistory) {
      let dailyChange = 0;
      const current = history[index];
      if (isSameDay(new Date(current.date), new Date(last7Dates[6]))) {
        const previous = history[index - 1];
        dailyChange =
          ((value - previous.marketPrice) / previous.marketPrice) * 100;
      }
      else {
        dailyChange = null as any
      }
      updateInvestment(ticker, current.marketPriceId, value, dailyChange, current.date);
    }
  };
  const getValue = (date: string) => {
    const history = investmentMarketPriceHistory?.find((hist) => isSameDay(new Date(date), new Date(hist.referenceDate)))
    return (history) ? history.marketPrice : 0;
  }
  return (
    <>
      {
        history.length !== 0 &&
        <TableRow key={ticker}>
          <TableCell sx={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white', minWidth: 100, border: '1px solid rgba(224, 224, 224, 1);', padding: 0, textAlign: 'center' }}>{ticker}</TableCell>

          {history &&
            history.map((his: any, index: number) =>
            (<TableCell sx={{ minWidth: 150, border: '1px solid rgba(224, 224, 224, 1);', padding: 0 }}><TextField
              defaultValue={
                getValue(his.date)
              }
              disabled={his.locked}
              onChange={(event) =>
                updateValue(parseFloat(event.target.value), index)
              }
              value={his.marketPrice}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputComponent: NumericFormatCustom as any,
              }}
              name="marketPrice"
              sx={{
                "& fieldset": { border: 'none' },
                margin: 0
              }}
            /></TableCell>))}

        </TableRow>
      }


    </>
  );
};
