import { CircularProgress, Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useUpdate } from "@refinedev/core";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import { IGrantVestmentRequest } from "interfaces/grant-vestment-request";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { moneyFormat } from "utils/money-format";

interface ModalProps {
  visible: boolean;
  data: {
    grantvestmentRequest: IGrantVestmentRequest | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED" | "PROCESSED";
  };
  close: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EvaluateGrantVestmentRequestModal: React.FC<ModalProps> = ({
  visible,
  close,
  data,
}) => {
  const [appliedAmount, setAppliedAmount] = useState(0);
  const [error, setError] = useState(false);

  const [rejectionReason, setRejectionReason] = useState("");
  const { mutate, isLoading } = useUpdate();
  const navigate = useNavigate();

  useEffect(() => {
    if (data.grantvestmentRequest?.status === "PENDING") {
      setAppliedAmount(data.grantvestmentRequest.requestedAmount);
    }

    if (data.grantvestmentRequest?.status === "APPROVED") {
      setAppliedAmount(data.grantvestmentRequest.appliedAmount);
    }
  }, [data.grantvestmentRequest]);
  console.log(data.value);
  return (
    <Dialog open={visible} onClose={() => close(false)}>
      <DialogTitle color="primary">
        {data.value === "APPROVED"
          ? "Approve"
          : data.value === "PROCESSED"
          ? "Process"
          : "Reject"}{" "}
        GrantVestment Request
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Are you sure you want to{" "}
          {data.value === "APPROVED"
            ? "Approve"
            : data.value === "PROCESSED"
            ? "process"
            : "reject"}{" "}
          this GrantVestment request?
        </Typography>
        {data.value === "APPROVED" && (
          <div>
            <TextField
              value={moneyFormat(data?.grantvestmentRequest!.requestedAmount)}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
              label="Requested Amount"
              name="requestedAmount"
              disabled
            />

            <TextField
              value={appliedAmount}
              error={error}
              helperText={error && "The applied amount is required"}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
              label="Applied Amount"
              name="appliedAmount"
              onChange={(e) => {
                setAppliedAmount(e.target.value as any);
                setError(false);
              }}
            />
          </div>
        )}{" "}
        {data.value === "REJECTED" && (
          <>
            <TextField
              value={rejectionReason}
              error={error}
              helperText={error && "The rejection reason is required"}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Rejection reason"
              name="rejectionReason"
              multiline={true}
              rows={3}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          </>
        )}
        {data.value === "PROCESSED" && (
          <div>
            <Typography variant="caption" paddingLeft={1}>
              Initially requested amount:
              {data.grantvestmentRequest?.requestedAmount}
            </Typography>
            <br></br>
            <Typography variant="caption" paddingLeft={1}>
              Applied amount at the acceptation step:
              {data.grantvestmentRequest?.appliedAmount}
            </Typography>
            <TextField
              value={appliedAmount}
              error={error}
              helperText={error && "The applied amount is required"}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
              label="Confirm Applied Amount"
              name="appliedAmount"
              onChange={(e) => {
                setAppliedAmount(e.target.value as any);
                setError(false);
              }}
            />

            <Typography variant="caption">
              *You can not undo this action once you process the request.{" "}
            </Typography>
          </div>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button
            variant="contained"
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => {
              if (
                (!appliedAmount && data.value === "APPROVED") ||
                (!appliedAmount && data.value === "PROCESSED")
              ) {
                setError(true);
              } else {
                mutate(
                  {
                    resource: "grant-vestment-requests",
                    values:
                      data.value === "APPROVED"
                        ? {
                            status: data.value,
                            appliedAmount: parseFloat(
                              appliedAmount.toString().replace(",", "")
                            ),
                          }
                        : data.value === "REJECTED"
                        ? {
                            rejectionReason: rejectionReason,
                            status: data.value,
                          }
                        : {
                            status: data.value,
                            appliedAmount: parseFloat(
                              appliedAmount.toString().replace(",", "")
                            ),
                          },
                    id: `${
                      data.grantvestmentRequest?.grantVestmentRequestId as any
                    }/status`,
                  },
                  {
                    onSuccess: () => {
                      navigate("/contribution-transactions");
                      setError(false);
                      close(false);
                      data.value === "APPROVED"
                        ? navigate("/grant-vestments-requests/status/approved")
                        : data.value === "PROCESSED"
                        ? navigate("/grant-vestments-requests/status/processed")
                        : navigate("/grant-vestments-requests/status/rejected");
                    },
                  }
                );
              }
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              close(false);
              setError(false);
            }}
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
