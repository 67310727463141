import React from "react";
import { useDataGrid, List, DateField } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { moneyFormat } from "utils/money-format";
import { ITransferTransaction, TRANSFER_TRANSACTION_TYPE_ENUM } from "interfaces/transfer-transaction";

export const RejectedTransferTransactionList: React.FC<
  IResourceComponentsProps
> = () => {
  const { dataGridProps } = useDataGrid<ITransferTransaction>({
    queryOptions: { enabled: false },
  });
  const { data, isLoading } = useCustom({
    url: `transfer-transactions`,
    method: "get",
    config: {
      filters: [
        {
          field: "status",
          operator: "eq",
          value: "REJECTED",
        },
      ],
    },
  });

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "transferId",
        flex: 1,
        headerName: "ID",
        maxWidth: 50,
        renderCell: function render({ value }: any) {
          return value;
        },
      },
      {
        field: "senderFund",
        flex: 1,
        headerName: "From",

        renderCell: function render({ value }: any) {
          return value.fundAccountName;
        },
      },
      {
        field: "receiverFund",
        flex: 1,
        headerName: "To",

        renderCell: function render({ value }: any) {
          return value.fundAccountName;
        },
      },
      {
        field: "user",
        flex: 1,
        headerName: "Advisor",

        renderCell: function render({ value }: any) {
          return `${value.firstName} ${value.lastName}`;
        },
      },
      {
        field: "transferType",
        flex: 1,
        headerName: "Type",
      },
      {
        field: "reference",
        flex: 1,
        headerName: "Reference",
      },

      {
        field: "amount",
        flex: 1,
        headerName: "Value",

        renderCell: function render({ row }) {
          if (row.transferType === TRANSFER_TRANSACTION_TYPE_ENUM.INVESTMENTS) {
            return <>{row.amount} unit(s)</>;
          }
          else {
            return <>${moneyFormat(row.amount)}</>;
          }
        },
      },
      {
        field: "status",
        flex: 1,
        headerName: "Status",
        width: 80
      },
      {
        field: "executionDate",
        flex: 1,
        headerName: "Date",
        renderCell: function render({ value }: any) {
          return <DateField value={value} />;
        },
      }
    ],
    []
  );

  return (
    <>
      <List title="Rejected Transfer Transaction List">
        {!isLoading && (
          <DataGrid
            {...dataGridProps}
            rows={data?.data?.data}
            getRowId={(row) => row.transferId}
            columns={columns}
            autoHeight
            loading={isLoading}
          />
        )}
      </List>
    </>
  );
};
